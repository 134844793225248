<template>
  <div class="step-one">
    <van-cell-group>
      <template #title>
        <div class="theme-color">
          <span>专业投资者选项</span>
        </div>
        <div class="title theme-color">
          <span>您是否专业投资者？</span>
        </div>
      </template>
      <van-cell>
        <van-radio-group
          v-model="form.major_invester"
          direction="horizontal"
          class="option"
        >
          <van-radio name="是">是</van-radio>
          <van-radio name="否">否</van-radio>
        </van-radio-group>
      </van-cell>
    </van-cell-group>
    <div v-if="form.major_invester === '是'" class="info theme-color">
      <div>根据相关监管要求，请先阅读以下材料：</div>
      <div class="theme-color">
        <span>[{{form.major_invester_read === '是' ? '已' : '未'}}阅读]</span>
        <router-link class="link" to="/majorOption/appendix">
          <span>专业投资者附录I</span>
        </router-link>
      </div>
    </div>
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="next" :disabled="disabledNext">
        下一步
      </van-button>
      <van-button class="btn" @click="before">
        上一步
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Button, CellGroup, Cell, RadioGroup, Radio,
} from 'vant';
import { useStore } from 'vuex';
import {
  onMounted,
  watch,
  ref,
  reactive,
  computed,
} from 'vue';
import httpApi from '../../utils/httpApi';
import httpAddApi from '../../utils/httpAddApi';

let submitForm;
export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Cell.name]: Cell,
  },
  data() {
    return {
    };
  },
  methods: {
    async next() {
      if (this.disabledNext.value) return;
      const params = JSON.parse(JSON.stringify(this.form));
      const httprequest = this.isAddAccount
        ? httpAddApi.updateMajorInvesterInfo : httpApi.postMajorOptionStepOne;
      const result = await httprequest(params);
      if (result) {
        if (this.isAddAccount) {
          await this.$api.submitLog({ type: 'submitAddMajorInvesterInfo' });
          const addAccountInfo = await httpAddApi.getOpenAccountInfo();
          if (!addAccountInfo) return;
          if (this.form.major_invester === '是') {
            this.$router.push({ path: '/majorOption/step_two' });
          } else if (addAccountInfo.add_account_type === '证券保证金账户-期货账户') {
            this.$router.push({ path: '/riskDisclosure/step_one' });
          } else {
            this.$router.push({ path: '/riskDisclosure/step_two' });
          }
        } else {
          const res = await this.$api.getHasFinishedRiskTropism();
          if (this.form.major_invester === '是') {
            this.$router.push({ path: '/majorOption/step_two' });
          } else if (res) {
            if (res.data.hasFinishedRiskTropism) {
              this.$router.push({ path: '/riskOrientation/step_two' });
            } else {
              this.$router.push({ path: '/riskOrientation/step_one' });
            }
          }
        }
      }
    },
    before() {
      this.$router.back();
    },
  },
  setup() {
    const form = reactive({
      major_invester: '',
      major_invester_read: '',
    });
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    onMounted(async () => {
      store.commit('setTitleValue', '风险认知');
      store.commit('setIsEnterMajor', true);
      if (isAddAccount.value) {
        store.commit('setStepsValue', ['风险认知(2/4)', '提交资料']);
        store.commit('setStepsActive', 0);
      } else {
        store.commit('setStepsValue', ['填写资料', '风险认知(2/4)', '提交资料']);
        store.commit('setStepsActive', 1);
      }
      const httprequest = isAddAccount.value
        ? httpAddApi.getMajorInvesterInfo : httpApi.getMajorOptionStepOne;
      const result = await httprequest();
      if (!result) return;
      Object.getOwnPropertyNames(form).forEach((item) => {
        if (result.data[item] !== undefined) {
          form[item] = result.data[item];
        }
      });
    });

    const disabledNext = ref(true);
    watch(form, (val) => {
      submitForm = { ...val };
      disabledNext.value = true;
      if (submitForm.major_invester === '否') {
        disabledNext.value = false;
        delete submitForm.major_invester_read;
      } else if (submitForm.major_invester_read === '是') {
        disabledNext.value = false;
      }
      httpApi.formLog(submitForm, disabledNext);
    });
    return {
      form,
      isAddAccount,
      disabledNext,
    };
  },
};
</script>

<style lang="less">
  .step-one {
    text-align: left;
    .title {
      margin-top: @space-1;
      color: @font-color-0;
    }
    .option {
      justify-content: space-around;
    }
    .info {
      margin-top: @space-1;
      padding-left: @space-2;
      font-size: @font-size-2;
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }
</style>
